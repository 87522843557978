<template>
    <v-container fluid>
        <v-row dense class="pt-2">
            <v-col cols="12">
                <v-row justify="center">
                    <v-col cols="12" md="10">
                        <v-row justify="center" class=" py-3">
                            <h1>Welcome to {{ userName }} Portal</h1>
                        </v-row>

                        <v-divider class="mt-2"></v-divider>

                        <v-row justify="center" class="pt-2">
                           
                            <v-col cols="12" md="3">
                                <v-card to="/student-attendance" elevation="1" color="lime">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="65">mdi-account-group</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Attendance Report</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/student-result" elevation="1" color="orange">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="65">mdi-hammer-screwdriver</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Student Result</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/payment-report" elevation="1" color="pink">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="65">mdi-cash-register</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Payment Report</div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'StudentDashboard',

    data: () => ({
        userName: null
    }),

    created() {
        let userData = JSON.parse(localStorage.getItem('userData'))
        this.userName = userData.name;
    },

    methods: {

    }
}
</script>

<style lang="scss" scoped>
.module-card-text {
    padding: 20px 5px 3px;
    text-align: center;

    .v-icon {
        color: #263238 !important;
    }
}
.module-card-title {
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
}
</style>
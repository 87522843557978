<template>
	<component :is="layout"></component>
</template>

<script>
import defaultLayout from './layouts/Default';
import simpleLayout from './layouts/Simple';
import studentLayout from './layouts/Student';
export default {
	components: {
		defaultLayout,
		simpleLayout,
		studentLayout
	},

	computed: {
		layout () {
			return this.$route.meta.layout || 'defaultLayout';
			// return (this.$route.name == null || this.$route.name == 'Login' || this.$route.name == 'StudentLogin') ? 'simpleLayout' : 'defaultLayout'
			// if(this.$route.name == null || this.$route.name == 'StudentLogin') {
			// 	return 'simpleLayout'
			// } else if(this.$route.name == 'StudentDashboard') {
			// 	return 'studentLayout';
			// } else {
			// 	return 'defaultLayout';
			// }
		}
	}
}
</script>

<style>

</style>
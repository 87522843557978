<template>
	<v-app>
        <v-navigation-drawer v-model="drawer" :clipped="clipped" fixed app dark width="200">
            <v-container style="padding-top:10px;padding-bottom:10px;border-bottom: 1px solid #616161;background-color:#263238;">
                <v-row>
                    <v-col cols="12" md="12" class="text-center" style="padding-top:5px;padding-bottom:5px;">
                        <v-btn color="warning" fab outlined rounded x-small width="23%" height="26" depressed class="left-menu-button" :to="dashboardMenu">
                            <v-icon color="white">mdi-apps</v-icon>
                        </v-btn>
                        <v-btn color="success" fab outlined rounded x-small width="23%" height="26" depressed class="left-menu-button">
                            <v-icon color="white">mdi-account-details</v-icon>
                        </v-btn>
                        <v-btn color="info" fab outlined rounded x-small width="23%" height="26" depressed class="left-menu-button">
                            <v-icon color="white">mdi-lock-reset</v-icon>
                        </v-btn>
                        <v-btn color="error" fab outlined rounded x-small width="23%" height="26" depressed class="left-menu-button">
                            <v-icon color="white">mdi-logout</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-list dense class="left-menu">
                <v-list-item v-for="(menu, i) in menuItems" :key="i" :to="menu.to" active-class="left-menu-active">
                    <v-list-item-action class="mr-2 py-2">
                        <v-icon>{{ menu.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="menu.title" />
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar :clipped-left="clipped" fixed app dense color="blue lighten-1" dark flat>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title style="margin-left: -20px" v-text="title" />
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="rightDrawer = !rightDrawer">
                <v-icon>mdi-account-circle</v-icon>
            </v-btn>
        </v-app-bar>
        <v-main>
			<v-container fluid class="pa-0 mt-1">
				<v-row dense>
					<v-col class="py-0">
						<router-view />
					</v-col>
				</v-row>
			</v-container>
		</v-main>
        <v-navigation-drawer v-model="rightDrawer" :right="true" :hide-overlay="true" temporary fixed light>
            <template v-slot:prepend>
                <v-list-item two-line>
                    <v-list-item-avatar class="mr-1">
                        <v-icon x-large color="primary">mdi-account-circle</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ userFullName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ userName }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <v-divider></v-divider>

            <v-list dense nav class="mt-2">
                <v-list-item link :to="`/change-password`">
                    <v-list-item-icon>
                        <v-icon>mdi-lock-reset</v-icon>
                    </v-list-item-icon>
                    <!-- <v-list-item-content>
                        <v-list-item-title>Change Password</v-list-item-title>
                    </v-list-item-content> -->
                </v-list-item>
                <v-list-item link @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Sign Out</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <snackbar></snackbar>
        <v-footer :fixed="fixed" app dark color="#424242">
            <span style="font-size:13px;">Design & Developed by Link-Up Technology</span>
        </v-footer>
	</v-app>
</template>

<script>
import snackbar from '../components/snackbar.component';
export default {
	components: {
		snackbar
	},

	data: () => ({
        clipped: true,
        drawer: true,
        fixed: false,
        right: true,
        rightDrawer: false,
        title: 'Student Portal',
        userFullName: '',
        userName: ''
	}),

	computed: {
		menuItems() {
			return this.$store.getters['student/menuItems'];
		},

		dashboardMenu() {
			let userType = this.$store.getters['authorized/user'].userType;
			if(userType == 's') return '/student-panel';
			else return '/';
		}
	},

	created() {
        let userData = JSON.parse(localStorage.getItem('userData'))

		if(userData != '' && userData.userType != 's') {
			this.$router.push('/')
		}

		this.userFullName = userData.name;
        this.userName = userData.username;
	},

	methods: {
        logout() {
            this.$store.dispatch('authorized/logout')
			let logoutRoute = '/studentportal';    
            this.$router.push(logoutRoute);
        }
	}
};
</script>

<style lang="scss" scoped>
	.v-list {
		padding-top: 0;
		padding-bottom: 0;
		
		.v-list-item__action {
			margin: 0;
		}
	}
	
	.left-menu-button {
		border-width: 2px;
		background-color: #424242;
	}
	
	.v-list-item__title {
		font-weight: normal!important;
	}

	.left-menu {
		.v-list-item {
			border-bottom: 1px solid #2e2e2e;
			background-color: #212121;
			.v-list-item__title {
				color: #BDBDBD;
			}
		}
	}

	.left-menu-active {
		background-color: #121111!important;
		&::before {
			opacity: 0!important;
		}
	}
</style>

export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            fees: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        fees(state) {
            return state.fees.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setFees(state, fees) {
            state.fees = fees
        },
    },

    actions: {
        async getFeesSettings(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-fees-settings`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setFees', res.data.fees);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveFeesSetting(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-fees-setting';
            } else {
                url = 'add-fees-setting';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteFeesSetting(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-fees-setting/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getFeesSettings');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async changeStatus(context, payload) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/change-fees-status`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}
